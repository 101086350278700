<template>
  <div class="search mt-2 mt-lg-0 d-flex align-items-center justify-content-between">
    <h5>Tìm kiếm nhiều trong tháng</h5>
    <div class="search__item justify-content-end">
      <button
        @click="$router.push({name:'sim-phong-thuy'})"
        class="b2c-buy-btn search_for_month"
      >
        Sim phong thuỷ
      </button>
      <button
        @click="handlesearch(item.Id)"
        v-for="(item, index) of dataSearchMonthly"
        class="b2c-buy-btn search_for_month"
        :key="item.Id"
        :class="isClick && isClick === item.Id ? 'red-bg' : ''"
      >
        {{ item.Name }}
      </button>
    </div>
  </div>
</template>
<script>
import useJwt from "@/auth/jwt/useJwt";
export default {
  name: "SearchForMonth",
  props: {
    dataSearchMonthly: {
      type: Array,
      default: [],
    },
    isSeachMonth: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    if (
      this.$route.query &&
      this.$route.query.sim_category_id_sort &&
      this.$route.query.sim_category_id_sort !== "null"
    ) {
      this.handlesearch(Number(this.$route.query.sim_category_id_sort));
    }
  },
  data() {
    return {
      isClick: null,
    };
  },
  watch: {
    isSeachMonth(val) {
      if (val) {
        this.handlesearch(-1);
      }
    },
  },
  methods: {
    handlesearch(index) {
      this.isClick = index;
      if (index !== -1) {
        this.$emit("handleSeachMonth", index);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.b2c-buy-btn {
  min-width: 120px;
  height: 38px;
  font-weight: 700;
  border-radius: 12px !important;
  font-size: 14px;
  font-weight: 700;
}

.gap-11 {
  gap: 15px;
}

.search {
  h5 {
    color: #424242;
    font-weight: 600;
    font-size: 20px !important;
  }
  max-width: 122.4rem;
  margin: 0 auto;

  &__item {
    display: flex;
    // justify-content: center;
    gap: 6px;
    flex-wrap: wrap;
  }
  &__item button:hover {
    background-color: red;
    color: white;
    transition: all 0.5s ease;
  }
}
@media screen and (max-width: 1099px) {
  .search {
    display: block !important;
  }
  h5 {
    margin-bottom: 15px;
  }
  .search__item {
    justify-content: center !important;
  }
}
@media screen and (max-width: 600px) {

  .search {
    h5 {
      font-size: 16px !important;
    }
    &_item {
      gap: 6px;
    }
  }
  .search__item {
    gap: 6px;
  }

  .b2c-buy-btn {
    width: 107px !important;
    max-width: 107px !important;
    min-width: 107px !important;
    height: 34px;
    font-size: 12px;
  }
}

.red-bg {
  background-color: red;
  color: white;
  transition: all 0.5s ease;
}
</style>